// TODO: consider removing/replacing, when IE support is dropped
@mixin defined {
  // legacy fallback
  // (applied by `BoltElement#connectedCallback()`)
  &[bolt-defined] {
    @content;
  }

  // modern
  &:defined {
    @content;
  }
}
