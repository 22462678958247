@use 'tokens' as *;
@use 'utils/breakpoint' as Breakpoint;
@use 'utils/pseudo' as Pseudo;
@use 'utils/when' as When;

$-accent--fallback: currentColor;
$-accent-width: $bolt-spacing-sm; // 8px
$-boxShadow--focused: $bolt-interactive-focus;
$-fontSize: $bolt-font-size-lg;
$-fontSize--sm: $bolt-font-size-md;
$-padding: $bolt-spacing-md;
$-padding-bottom: ($bolt-spacing-md - $-accent-width);
$-transitionDuration: $bolt-transition-timing-med;

@mixin layout {
  // common + fallback
  display: block; // similar to a heading
  margin: 0;
  padding: 0;

  // post-upgrade (i.e., `:defined`)
  @include When.defined {
    display: inline-block;
    padding: $-padding $-padding $-padding-bottom $-padding;

    // TODO: move to `bolt-tabset > tablabel` context
    // only applies to all but last of bolt-tablabel siblings
    &:not(:last-of-type) {
      margin: 0 $bolt-spacing-md 0 0;
    }
  }
}

@mixin _focused {
  box-shadow: $-boxShadow--focused;
  outline: none;
}
// removes visual styles of _focused()
@mixin _noFocus {
  box-shadow: none;
}

@mixin appearance {
  // common + fallback styles
  background: none;
  border: 0 solid transparent;
  cursor: pointer;
  font-size: $-fontSize;
  opacity: 1;

  @include Breakpoint.media-down('sm') {
    font-size: $-fontSize--sm;
  }

  @include When.defined {
    border-width: 0 0 $-accent-width 0;
    opacity: 0.65; // FIXME: use token
    transition:
      opacity $-transitionDuration,
      box-shadow $-transitionDuration;
    white-space: nowrap;

    &[aria-selected="true"] {
      border-color: $-accent--fallback; // IE
      border-color: var(--bolt-interactive-color-accent); // modern browsers
      opacity: 1;
    }

    @include Pseudo.hover {
      opacity: 1;
    }

    @include Pseudo.focus {
      // legacy browser fallback
      @include _focused;

      // modern browsers
      &:not(:focus-visible) { // TODO: make Pseudo.* mixin?
        @include _noFocus;
      }
    }
    // modern browsers
    @include Pseudo.focusVisible {
      @include _focused;
    }

    @include Pseudo.active {
      box-shadow: none;
      outline: none;
    }
  }
}

@mixin apply {
  bolt-tablabel {
    @content;
  }
}
