@if($load-gotham){
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-400.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-400-Italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-500.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-500-Italic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-700.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "nw-primary";
    src: url("https://media.nationwide.com/bolt/resources/fonts/Gotham-700-Italic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: "Tiempos Headline";
    src:
      url("https://media.nationwide.com/bolt/resources/fonts/Tiempos-Headline-600.woff") format("woff"),
      url("https://media.nationwide.com/bolt/resources/fonts/Tiempos-Headline-600.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
}
