$alignments: ("top": "vertical", "middle": "vertical", "bottom": "vertical", "left": "text", "center": "text", "right": "text"); 

.bolt-table {
    overflow-x: auto; 
    text-align: left; 

    &:not(.bolt-table-fit-content) table{
        width: 100%;
    }

    table {
        border-collapse: collapse; 
        border-spacing: 0;
        border: 1px solid $bolt-color-neutral-84;
    }
      
    th, td {
    padding: $bolt-spacing-md $bolt-spacing-lg;
    border: 1px solid $bolt-color-neutral-84;
    vertical-align: top; 
    }
    
    tr {
    background-color: $bolt-color-white; 
    color: $bolt-color-rebrand-charcoal; 
    }

    &-striped tr:nth-child(odd) {
        background-color: $bolt-color-neutral-96;
    }

    th {
        background-color: $bolt-color-neutral-42; 
        color: $bolt-color-white; 
        &.bolt-table-sub-head{
            background: $bolt-color-neutral-92;
            color: $bolt-color-rebrand-charcoal; 
        }
    }

    &.bolt-table-align, .bolt-table-align {
        @each $alignment, $type in $alignments{
            &-#{$alignment} th, &-#{$alignment} td  {
                #{$type}-align: #{$alignment};
            }
        }
    }

    th.bolt-table-align, td.bolt-table-align {
        @each $alignment, $type in $alignments{
            &-#{$alignment}, &-#{$alignment} {
                #{$type}-align: #{$alignment};
            }
        }
    }

    &-blue th {
        background: $bolt-color-brand-blue-medium; 
        
        &.bolt-table-sub-head {
            background: $bolt-color-brand-blue-medium-25;
        }
    }

    &-muted-blue th {
        background: $bolt-color-brand-muted-blue-medium; 
        
        &.bolt-table-sub-head {
            background: $bolt-color-brand-muted-blue-medium-25;
        }
    }

    &-purple th {
        background: $bolt-color-brand-purple-medium; 
        
        &.bolt-table-sub-head {
            background: $bolt-color-brand-purple-medium-25;
        }
    }
}
