bolt-modal{
  img {
    max-width: 100%;
  }

  .bolt-container, .bolt-container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
