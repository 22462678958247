:root{
    --bolt-tab-group-gradient-left: linear-gradient(to right, rgba(255,255,255,255) 10%, rgba(255,255,255,0));
    --bolt-tab-group-gradient-right: linear-gradient(to left, rgba(255,255,255,255) 10%, rgba(255,255,255,0));
    --bolt-tab-group-underline-bg: #{$bolt-color-blue};
    --bolt-tab-group-underline-opacity: 1.0;
}

@each $name, $value in $bolt-backgrounds{
    @if (color-contrast($value, $bolt-color-rebrand-charcoal, $bolt-color-white) == $bolt-color-white){
        .bolt-background-#{$name}{
            --bolt-tab-group-underline-bg: #{$bolt-color-white};
            --bolt-tab-group-underline-opacity: 75%;
        }
    }
    .bolt-background-#{$name}{
        --bolt-tab-group-gradient-left: linear-gradient(to right, #{$value} 10%, rgba(0,0,0,0));
        //background-image: linear-gradient(to right, $value 10%, rgba($value, 0));
    }
    .bolt-background-#{$name}{
        --bolt-tab-group-gradient-right: linear-gradient(to left, #{$value} 10%, rgba(0,0,0,0));
        //background-image: linear-gradient(to left, $value 10%, rgba($value, 0));
    }

}