:root{
    @each $name, $value in $bolt-font{
        --bolt-font-#{$name}: #{$value};
    }
    @each $name, $value in $bolt-spacing{
        --bolt-spacing-#{$name}: #{$value};
    }
    @each $name, $value in $bolt-colors{
        --bolt-color-#{$name}: #{$value};
    }
}
