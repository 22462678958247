/* Bolt.Typography.Lists */
@use 'tokens';

@mixin _list() {
  margin: 0 0 tokens.$bolt-spacing-xxl tokens.$bolt-spacing-lg;
}
@mixin _nested-list() {
  margin-top: tokens.$bolt-spacing-xs;
  margin-bottom: tokens.$bolt-spacing-sm;
}
@mixin _reset() {
  ul, ol, li {
    border: 0;
    font: inherit;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
}

/*
  Browser-default UL list-style-type (based on nesting depth)
  +------------+-----------------+
  | <UL> depth | list-style-type |
  +------------+-----------------+
  | 0          | disc            | ul
  | 1          | circle          | :is(ul, ol) ul
  | 2+         | square          | :is(ul, ol) :is(ul, ol) ul
  +------------+-----------------+

  Bolt OL list-style-type (based on nesting depth)
  +------------+-----------------+-----------------+
  | <OL> depth | list-style-type | example         |
  +------------+-----------------+-----------------+
  | 0          | decimal         | 1, 2, 3, ...    | ol
  | 1          | lower-latin     | a, b, c, ...    | :is(ul, ol) ol
  | 2+         | lower-roman     | i, ii, iii, ... | :is(ul, ol) :is(ul, ol) ol
  +------------+-----------------+-----------------+
*/
.bolt-typography {
  @include _reset;

  // FIXME: (1) does not result in a value in our modular scale
  // FIXME: (2) 24px / 18px = 1.333 line height which fails WCAG 1.4.12 criteria
  li {
    line-height: (tokens.$bolt-spacing-lg + tokens.$bolt-spacing-xs); // ~24px // (1) (2)
    margin-bottom: tokens.$bolt-spacing-md;
  }

  ul, ol { // [!IE] :is(ul, ol)
    @include _list;

    ol { // 1-deep
      list-style-type: lower-latin;

      // FIXME: Is this style still necessary?
      &[start] {
        margin-top: tokens.$bolt-spacing-xl;
      }
    }

    ul, ol { // [!IE] :is(ul, ol)
      @include _nested-list;

      li {
        margin-bottom: tokens.$bolt-spacing-sm;
      }

      ol { // 2+ deep
        list-style-type: lower-roman;
      }
    }
  }
}
