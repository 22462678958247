html {
    box-sizing: border-box; 
    color: $bolt-color-rebrand-charcoal;
}

*,
*::before,
*::after {
box-sizing: inherit;
}

.bolt-typography body {
    margin: 0; 
}