@use "__config" as Icon;

// applied if <bolt-icon> detects that it is a child of an <a>
a.bolt-remove-underline {
  &,
  &:visited,
  &:visited:focus,
  &:focus,
  &:active {
    border-bottom: 0;
  }
}

@include Icon.apply {
  @include Icon.base;

  &[size="sm"] {
    @include Icon.size(sm)
  }

  @each $name, $value in Icon.$colors {
    &[color="#{$name}"] {
      color: #{$value};
    }
  }
}

