@use "theme" as Theme;

// NOTE: Used to reset custom properties in (portions of) components that should not be affected by background class logic.

bolt-accordion,
bolt-button-bar,
bolt-contextual-help[display="overlay"] *,
bolt-header,
bolt-modal,
bolt-notification, bolt-alert,
bolt-tile {
  @include Theme.defaults;

  .bolt-theme--legacy & {
    @include Theme.legacy-defaults;
  }
}
