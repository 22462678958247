@use "sass:map";
@use "sass:math";
@use "utils/breakpoint" as Breakpoint;

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include Breakpoint.media-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin-right: math.div(-$gutter, 2);
  margin-left: math.div(-$gutter, 2);
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  padding-right: math.div($gutter, 2);
  padding-left: math.div($gutter, 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 math.percentage(math.div($size, $columns));
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: math.percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, math.percentage($num));
}

@mixin bolt-col-size($size: "") {
  @if $size == "auto" {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; // Reset earlier grid tiers
  } @else if $size == "" {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  } @else {
    @include make-col($size);
  }
}

@mixin bolt-col($p1: false, $p2: false) {
    /* normalizes parameters to allow for any of
    @include bolt-col;
    @include bolt-col(12);
    @include bolt-col(sm);
    @include bolt-col(sm, 12);
  */

  $size: "";
  $breakpoint: "all";
  @if $p1 and not $p2 {
    @if map.has-key($grid-breakpoints, $p1) {
      $breakpoint: $p1;
    } @else {
      $size: $p1;
    }
  } @else if $p1 and $p2 {
    $breakpoint: $p1;
    $size: $p2;
  }

  @include make-col-ready();
  @if $breakpoint == "all" {
    @include bolt-col-size($size);
  } @else {
    @include Breakpoint.media-up($breakpoint) {
      @include bolt-col-size($size);
      @content;
    }
  }
}
