@use 'sass:map';
@use 'variables' as vars;

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

$default-breakpoints: vars.$grid-breakpoints;

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> next(sm)
//    md
//    >> next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
@function next($name, $breakpoints: $default-breakpoints) {
  $result: null;
  $breakpoint-names: map.keys($breakpoints);
  $n: index($breakpoint-names, $name);

  @if ($n != null and $n < length($breakpoint-names)) {
    $result: nth($breakpoint-names, $n+1);
  }

  @return $result;
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> minWidth(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function minWidth($name, $breakpoints: $default-breakpoints) {
  $result: null;
  $min: map.get($breakpoints, $name);

  @if $min != 0 {
    $result: $min;
  }

  @return $result;
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> maxWidth(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function maxWidth($name, $breakpoints: $default-breakpoints) {
  $result: null;
  $next: next($name, $breakpoints);

  @if $next {
    $result: minWidth($next, $breakpoints) - 0.02;
  }

  @return $result;
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function infix($name, $breakpoints: $default-breakpoints) {
  $result: '-#{$name}';

  @if minWidth($name, $breakpoints) == null {
    $result: '';
  }

  @return $result;
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-up($args...) {
  $min: minWidth($args...);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-down($args...) {
  $max: maxWidth($args...);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-between($lower, $upper, $breakpoints: $default-breakpoints) {
  $min: minWidth($lower, $breakpoints);
  $max: maxWidth($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-only($args...) {
  $min: minWidth($args...);
  $max: maxWidth($args...);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-up($args...) {
      @content;
    }
  } @else if $min == null {
    @include media-down($args...) {
      @content;
    }
  }
}
