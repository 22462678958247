@use 'sass:string';
@use 'variables' as *;
@use 'functions' as *;
@use 'theme' as Theme;
@use 'utils/color' as Color;

// global theme
:root {
  @include Theme.defaults;
}

// background/contextual themes
.bolt-typography {
  @include Color.eachBackground using ($name, $value, $isDark) {
    $fg-color: Theme.$foreground-color; // default/fallback
    $interactive-color: Theme.$interactive-color; // default/fallback
    $bg-color: $value;
    $fg-contrast: Color.contrast($bg-color, $fg-color);
    $interactive-contrast: Color.contrast($bg-color, $interactive-color);
    $isRebrand: string.index($name, 'rebrand');

    @if $isRebrand {
      .bolt-background-#{$name} {
        /// ----- Update CSS Custom Properties -----
        --bolt-background-color: #{$bg-color};

        @if ($fg-contrast < 4.5){
          // Foreground text fails contrast
          $fg-color: $bolt-color-rebrand-white;
          --bolt-foreground-color: #{$fg-color};
          --bolt-icon-color: #{$fg-color};
        }

        @if ($interactive-contrast < 4.5){
          // interactive text fails contrast
          $interactive-color: text-contrast($bg-color, $fg-color, $bolt-color-white);
          --bolt-interactive-color: #{$interactive-color};
          --bolt-interactive-color-hover: #{$interactive-color};
          --bolt-interactive-color-active: #{$interactive-color};

          @if ($interactive-contrast < 3) {
            // interactive accents also fail contrast
            $interactive-color: $bolt-color-rebrand-pale-gray-25;
            --bolt-interactive-color-accent: #{$interactive-color};
          }
        }

        // ----- Additional Styles -----
        background-color: $bg-color; // legacy fallback
        background-color: var(--bolt-background-color);
        color: $fg-color; // legacy fallback
        color: var(--bolt-foreground-color);
      }
    }
  }
}
