@each $name, $bolt-background-value in $bolt-backgrounds{
    @if(should-output-background($name)){
        @if(map-has-key($bolt-interactive-colors, $name)){
            $primaryBgColor: map-get($bolt-interactive-colors, $name);
            $primaryTextColor: text-contrast($primaryBgColor, $bolt-color-rebrand-charcoal, $bolt-color-white, "primary main color for #{$name}");

            // init to establish scope and noticeable defaults
            $primaryHoverBg: #f00;
            $primaryActiveBg: #f00;
            $secondaryActiveBg: #f00;
            $secondaryHoverBg: #f00;

            @if(ends-with($name, '-medium') or ends-with($name, '-dark')){
                $primaryHoverBg: mix-blend-normal($bolt-interactive-overlay-primary-hover-dark, $primaryBgColor);
                $primaryActiveBg: mix-blend-normal($bolt-interactive-overlay-primary-active-dark, $primaryBgColor);
                $secondaryHoverBg: $bolt-interactive-overlay-secondary-hover-dark;
                $secondaryActiveBg: $bolt-interactive-overlay-secondary-active-dark;
            }
            @else{
                $primaryHoverBg: mix-blend-normal($bolt-interactive-overlay-primary-hover-light, $primaryBgColor);
                $primaryActiveBg: mix-blend-normal($bolt-interactive-overlay-primary-active-light, $primaryBgColor);
                $secondaryHoverBg: $primaryBgColor;
                $secondaryActiveBg: mix-blend-normal($bolt-interactive-overlay-secondary-active-light, $primaryBgColor);
            }

            $buttonColorSelector: "";
            @if ends-with($name, '-medium-25'){
                $plainName: str-replace($name, '-medium-25', '');
                $buttonColorSelector: ", bolt-button[color=#{$plainName}]";
            }
            @if ends-with($name, '-dark'){
              $buttonColorName: str-replace($name, '-dark', '-on-dark');
              $buttonColorSelector: ", bolt-button[color=#{$buttonColorName}]";
            }

            .bolt-background-#{$name} #{$buttonColorSelector}{
                // primary
                    //text - normal, active, hover
                    --bolt-button-primary-text: #{$primaryTextColor};
                    --bolt-button-primary-text-hover: #{text-contrast($primaryHoverBg, $bolt-color-rebrand-charcoal, $bolt-color-white)};
                    --bolt-button-primary-text-active: #{text-contrast($primaryActiveBg, $bolt-color-rebrand-charcoal, $bolt-color-white)};

                    //background - normal, active, hover
                    --bolt-button-primary-background: #{$primaryBgColor};
                    --bolt-button-primary-background-hover: #{$primaryHoverBg};
                    --bolt-button-primary-background-active: #{$primaryActiveBg};

                    //border - normal, active, hover
                    --bolt-button-primary-border: rgba(0,0,0,0);
                    --bolt-button-primary-border-hover: rgba(0,0,0,0);
                    --bolt-button-primary-border-active: rgba(0,0,0,0);

                // secondary
                    $secondaryHoverBlended: mix-blend-normal($secondaryHoverBg, $bolt-background-value);

                    //text - normal, active, hover
                    --bolt-button-secondary-text: #{text-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white,
                        "secondary main color for #{$name}")};
                    --bolt-button-secondary-text-hover: #{text-contrast($secondaryHoverBlended,
                        $bolt-color-rebrand-charcoal,
                        $bolt-color-white,
                        "secondary :hover color for #{$name}")};
                    --bolt-button-secondary-text-active: #{color-contrast($secondaryActiveBg,
                        $bolt-color-rebrand-charcoal,
                        $bolt-color-white)};
                    //background - normal, active, hover
                    --bolt-button-secondary-background: rgba(0,0,0,0);;
                    --bolt-button-secondary-background-hover: #{$secondaryHoverBg};
                    --bolt-button-secondary-background-active: #{$secondaryActiveBg};
                    //border - normal, active, hover
                    --bolt-button-secondary-border: #{color-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white)};
                    --bolt-button-secondary-border-hover: #{color-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white)};
                    --bolt-button-secondary-border-active: #{color-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white)};
                // tertiary
                    //text - normal, active, hover
                    --bolt-button-tertiary-text: #{text-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white,
                        "tertiary main color for #{$name}")};
                    --bolt-button-tertiary-text-hover: #{text-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white,
                        "tertiary main color for #{$name}")};
                    --bolt-button-tertiary-text-active: #{text-contrast(
                        $secondaryHoverBlended,
                        $bolt-color-rebrand-charcoal,
                        $bolt-color-white,
                        "tertiary :active color for #{$name}")};
                    //background - normal, active, hover
                    --bolt-button-tertiary-background: rgba(0,0,0,0);
                    --bolt-button-tertiary-background-hover: rgba(0,0,0,0);;
                    --bolt-button-tertiary-background-active: #{$secondaryHoverBg};
                    //border - normal, active, hover
                    --bolt-button-tertiary-border: rgba(0,0,0,0);
                    --bolt-button-tertiary-border-hover: #{color-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white)};
                    --bolt-button-tertiary-border-active: #{color-contrast($bolt-background-value,
                        $primaryBgColor,
                        $bolt-color-white)};
            }
        }
    }
}
