@use 'sass:color';
@use 'utils/color' as Color;
@use 'variables' as *;

:root {
  /* module: Bolt.Components.Tabset */
  --bolt-tabset-gradient--left: linear-gradient(to right, #fff 40%, #fff0);
  --bolt-tabset-gradient--right: linear-gradient(to left, #fff 40%, #fff0);
}
@include Color.eachBackground using ($name, $value, $isDark) {
  // Safari doesn't blend 'transparent' values correctly with color stops in a gradient,
  // so we have to use color.adjust() to manually apply the correct blend.
  $transparent: color.adjust($value, $alpha: -1);

  .bolt-background-#{$name} {
    /* module: Bolt.Components.Tabset */
    --bolt-tabset-gradient--left: linear-gradient(to right, #{$value} 40%, #{$transparent});
    --bolt-tabset-gradient--right: linear-gradient(to left, #{$value} 40%, #{$transparent});
  }
}
