@use 'tokens' as *;
@use 'theme' as Theme;
@use 'utils/when' as When;

$-boxShadow--focus: $bolt-interactive-focus-inset;
$-padding: $bolt-spacing-lg; // 20px
$-transition: $bolt-transition-timing-med;

@mixin reset {
  /* reset */
  @include Theme.defaults;

  .bolt-theme--legacy & {
    @include Theme.legacy-defaults;
  }
}

@mixin layout {
  /* layout */
  // common + fallback
  display: block;
  padding: $-padding;

  // post-upgrade (i.e., `:defined`)
  @include When.defined {
    // hidden, by default (before UI state sync)
    display: none;

    &[open] {
      display: block;
    }
  }
}

@mixin appearance {
  /* apperarance */
  transition: $-transition;

  &:focus-visible {
    box-shadow: $-boxShadow--focus;
    outline: none;
  }
}

@mixin apply {
  bolt-tabpanel {
    @content;
  }
}
