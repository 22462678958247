@each $color-name, $color-value in $bolt-button-colors {
  $primaryBgColor: $color-value;
  $primaryTextColor: text-contrast($primaryBgColor, $bolt-color-rebrand-charcoal, $bolt-color-white);
  $primaryHoverBg: mix-blend-normal($bolt-interactive-overlay-primary-hover-light, $primaryBgColor);
  $primaryActiveBg: mix-blend-normal($bolt-interactive-overlay-primary-active-light, $primaryBgColor);
  $secondaryHoverBg: $primaryBgColor;
  $secondaryActiveBg: mix-blend-normal($bolt-interactive-overlay-secondary-active-light, $primaryBgColor);

  // Update custom props for colored variations
  // Properties that do not change from default, such as transparent border, are ommitted
  bolt-button[color=#{$color-name}]{

    // primary
    --bolt-button-primary-text: #{$primaryTextColor};
    --bolt-button-primary-text-hover: #{text-contrast($primaryHoverBg, $bolt-color-rebrand-charcoal, $bolt-color-white)};
    --bolt-button-primary-text-active: #{text-contrast($primaryActiveBg, $bolt-color-rebrand-charcoal, $bolt-color-white)};

    --bolt-button-primary-background: #{$primaryBgColor};
    --bolt-button-primary-background-hover: #{$primaryHoverBg};
    --bolt-button-primary-background-active: #{$primaryActiveBg};

    // secondary
    --bolt-button-secondary-text: #{$primaryBgColor};
    --bolt-button-secondary-text-hover: #{text-contrast($secondaryHoverBg,
        $bolt-color-rebrand-charcoal,
        $bolt-color-white)};
    --bolt-button-secondary-text-active: #{text-contrast($secondaryActiveBg,
        $bolt-color-rebrand-charcoal,
        $bolt-color-white)};

    --bolt-button-secondary-background-hover: #{$secondaryHoverBg};
    --bolt-button-secondary-background-active: #{$secondaryActiveBg};

    --bolt-button-secondary-border: #{$primaryBgColor};

    // tertiary
    --bolt-button-tertiary-text: #{$primaryBgColor};
    --bolt-button-tertiary-text-hover: #{$primaryBgColor};
    --bolt-button-tertiary-text-active: #{text-contrast(
        $secondaryHoverBg,
        $bolt-color-rebrand-charcoal,
        $bolt-color-white)};

    --bolt-button-tertiary-background-active: #{$secondaryHoverBg};

    --bolt-button-tertiary-border-hover: #{$primaryBgColor};
  }
}
