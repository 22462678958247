/// Legacy Theme Definitions
@use 'variables' as *;
@use "functions" as *;

/// NOTE: all definitions treated as a default "Light" theme

$background-color: $bolt-color-white;
$foreground-color: $bolt-color-black;
$interactive-color: $bolt-color-blue;
$interactive-color--hover: $interactive-color;
$interactive-color--active: $interactive-color;
$interactive-color--accent: $interactive-color; // TODO: consider renaming for 5.0
$button-color: $interactive-color;
$icon-color: $foreground-color; // TODO: why not "inherit"?

// status colors
$success-color: $bolt-color-status-success;
$warning-color: $bolt-color-status-warning;
$error-color: $bolt-color-status-error;
$info-color: $bolt-color-status-info;


// NOTE: Please choose custom property names, carefully.
//       Custom properties defined in this mixin should be considered part of
//       the public theming interface/API.
@mixin defaults() {
  /* Theme/Legacy.defaults */

  /// ----- CURRENT -----
  --bolt-background-color: #{$background-color};
  --bolt-foreground-color: #{$foreground-color};
  --bolt-interactive-color: #{$interactive-color};
  --bolt-interactive-color-hover: #{$interactive-color--hover};
  --bolt-interactive-color-active: #{$interactive-color--active};
  --bolt-interactive-color-accent: #{$interactive-color--accent};
  --bolt-icon-color: #{$icon-color};

  // Status colors
  --bolt-color-status-success: #{$success-color};
  --bolt-color-status-warning: #{$warning-color};
  --bolt-color-status-error: #{$error-color};
  --bolt-color-status-info: #{$info-color};

  /// BUTTON (primary)
  ///text - normal, active, hover
  // TODO: use semantic var
  --bolt-button-primary-text: #{$bolt-color-white};
  // TODO: use semantic var
  --bolt-button-primary-text-hover: #{$bolt-color-white};
  // TODO: use semantic var
  --bolt-button-primary-text-active: #{$bolt-color-white};
  ///background - normal, active, hover
  --bolt-button-primary-background: #{$button-color};
  // TODO: use semantic var
  --bolt-button-primary-background-active: #{mix-blend-normal(
      $bolt-interactive-overlay-primary-active-light,
      $button-color)};
  // TODO: use semantic var
  --bolt-button-primary-background-hover: #{mix-blend-normal(
      $bolt-interactive-overlay-primary-hover-light,
      $button-color)};
  ///border - normal, active, hover
  --bolt-button-primary-border: #0000;
  --bolt-button-primary-border-active: #0000;
  --bolt-button-primary-border-hover: #0000;

  /// BUTTON (secondary)
  ///text - normal, active, hover
  --bolt-button-secondary-text: #{$button-color};
  // TODO: use semantic var
  --bolt-button-secondary-text-active: #{$bolt-color-white};
  // TODO: use semantic var
  --bolt-button-secondary-text-hover: #{$bolt-color-white};
  ///background - normal, active, hover
  --bolt-button-secondary-background: #0000;
  // TODO: use semantic var
  --bolt-button-secondary-background-active: #{mix-blend-normal(
      $bolt-interactive-overlay-secondary-active-light,
      $button-color)};
  --bolt-button-secondary-background-hover: #{$button-color};
  ///border - normal, active, hover
  --bolt-button-secondary-border: #{$button-color};
  --bolt-button-secondary-border-active: #0000;
  --bolt-button-secondary-border-hover: #0000;

  /// BUTTON (tertiary)
  ///text - normal, active, hover
  --bolt-button-tertiary-text: #{$button-color};
  // TODO: use semantic var
  --bolt-button-tertiary-text-active: #{$bolt-color-white};
  --bolt-button-tertiary-text-hover: #{$button-color};
  ///background - normal, active, hover
  --bolt-button-tertiary-background-active: #{$button-color};
  ///border - normal, active, hover
  --bolt-button-tertiary-border: #0000;
  --bolt-button-tertiary-border-active: #0000;
  --bolt-button-tertiary-border-hover: #{$button-color};
}
