@use 'sass:string';
@use 'variables' as *;
@use 'mixins' as *;
@use 'functions' as *;
@use 'theme' as Theme;
@use 'utils/color' as Color;

// global theme
.bolt-typography {
  .bolt-theme--legacy,
  &.bolt-theme--legacy {
    @include Theme.legacy-defaults;
  }
}

// background/contextual themes
.bolt-typography {
  @include Color.eachBackground using ($name, $value, $isDark) {
    $fg-color: Theme.$foreground-color; // default/fallback
    $bg-color: $value;
    $isLight: string.index($name, 'medium-25');
    $isRebrand: string.index($name, 'rebrand');

    @if not($isRebrand){
      .bolt-background-#{$name} {
        /// ----- Update CSS Custom Properties -----
        --bolt-background-color: #{$bg-color};

        @if $isLight {
          // Light background colors
          $fg-color: map-get($bolt-colors, 'brand-' + str-replace($name, 'medium-25', 'dark'));
          --bolt-foreground-color: #{$fg-color};
          --bolt-interactive-color: #{$fg-color};
          --bolt-interactive-color-hover: #{$fg-color};
          --bolt-interactive-color-active: #{$fg-color};
          --bolt-interactive-color-accent: #{$fg-color};
          --bolt-icon-color: #{$fg-color};
        }
        @else if $isDark {
          // Dark background colors
          $fg-color: $bolt-color-white;
          --bolt-foreground-color: #{$fg-color};
          --bolt-interactive-color: #{$fg-color};
          --bolt-interactive-color-hover: #{$fg-color};
          --bolt-interactive-color-active: #{$fg-color};
          --bolt-interactive-color-accent: #{$fg-color};
          --bolt-icon-color: #{$fg-color};
        }
        @else {
          // "medium" background (neither dark or light)
          $fg-color: Theme.$foreground-color;
        }

        // ----- Additional Styles -----
        background-color: $bg-color; // legacy fallback
        background-color: var(--bolt-background-color);
        color: $fg-color; // legacy fallback
        color: var(--bolt-foreground-color);

        @if $isLight {
          @include target-ie {
            a {
              color: $fg-color;
              border-bottom: 1px solid $fg-color;
            }

            // TODO: migrate to components/button
            .bolt-button-wc {
              background: transparent;
              color: $fg-color;
              border: 1px solid $fg-color;
            }
          }
        }
        @else if $isDark {
          @include target-ie {
            a {
              color: $fg-color;
              border-bottom: 1px solid $fg-color;
            }

            // TODO: migrate to components/button
            .bolt-button-wc {
              background: transparent;
              color: $fg-color;
              border: 1px solid $fg-color;
            }
          }
        }
        @else {
          @include target-ie {
            a {
              border-bottom: 1px solid Theme.$interactive-color;
            }
          }
        }
      }
    }
  }
}
