@use 'env';

// @use "utils/pseudo";
// .foo {
//   @include pseudo.hover { ... }
// }
//                +------------+
//                | env.$mode  |
// +--------------+------------+
// | selector     | DEV | PROD |
// +--------------+-----+------+
// | :hover       | X   | X    |
// +--------------+-----+------+
// | .mock-hover  | X   |      |
// +--------------+-----+------+
@mixin hover {
  &:hover {
    @content;
  }

  @include env.DEV {
    &.mock-hover {
      @content;
    }
  }
}

// @use "utils/pseudo";
// .foo {
//   @include pseudo.focus { ... }
// }
//                +------------+
//                | env.$mode  |
// +--------------+------------+
// | selector     | DEV | PROD |
// +--------------+-----+------+
// | :focus       | X   | X    |
// +--------------+-----+------+
// | .mock-focus  | X   |      |
// +--------------+-----+------+
@mixin focus {
  &:focus {
    @content;
  }

  @include env.DEV {
    &.mock-focus {
      @content;
    }
  }
}

// @use "utils/pseudo";
// .foo {
//   @include pseudo.focusVisible { ... }
// }
//                        +------------+
//                        | env.$mode  |
// +----------------------+------------+
// | selector             | DEV | PROD |
// +----------------------+-----+------+
// | :focus-visible       | X   | X    |
// +----------------------+-----+------+
// | .mock-focus-visible  | X   |      |
// +----------------------+-----+------+
@mixin focusVisible {
  &:focus-visible {
    @content;
  }

  @include env.DEV {
    &.mock-focus-visible {
      @content;
    }
  }
}

// @use "utils/pseudo";
// .foo {
//   @include pseudo.active { ... }
// }
//                +------------+
//                | env.$mode  |
// +--------------+------------+
// | selector     | DEV | PROD |
// +--------------+-----+------+
// | :active      | X   | X    |
// +--------------+-----+------+
// | .mock-active | X   |      |
// +--------------+-----+------+
@mixin active {
  &:active {
    @content;
  }

  @include env.DEV {
    &.mock-active {
      @content;
    }
  }
}
