@import '../variables';

// FIXME: remove top-level %placeholder-class and/or migrate to @mixin
%component-reset{
    border: 0;
    box-sizing: border-box;
    font: inherit;
    font-weight: $bolt-font-weight-regular;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: none;
    vertical-align: baseline;
}
