@use "sass:map";
@use "sass:math";
@use "utils/breakpoint" as Breakpoint;

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: math.div($gutter,2);
    padding-left: math.div($gutter,2);
  }

  @each $breakpoint in map.keys($breakpoints) {
    $infix: Breakpoint.infix($breakpoint, $breakpoints);

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .bolt-col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .bolt-col#{$infix},
    .bolt-col#{$infix}-auto {
      @extend %grid-column;
    }

    @include Breakpoint.media-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .bolt-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .bolt-col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .bolt-col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .bolt-order#{$infix}-first { order: -1; }

      .bolt-order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .bolt-order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .bolt-offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}
