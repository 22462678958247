@use 'tokens' as *;

// applies to `bolt-tabset { ... }` and
// <bolt-tabset> shadow `:host { ... }`
@mixin layout {
  display: block;
}

@mixin appearance {
  // TBD...
}

@mixin apply {
  bolt-tabset {
    @content;
  }
}
