@use "../../variables" as vars;

$colors: vars.$bolt-icon-colors;
$transition: vars.$bolt-transition-timing-med;

// Apply to
//  - :host (Shadow DOM)
//  - bolt-icon (Light DOM)
@mixin base {
  color: inherit;
  display: inline-block;
  flex: 0 0 auto;

  @include size(md);
}

@mixin size($size: md) {
  @if $size == sm {
    height: vars.$bolt-size-icon-sm;
    width: vars.$bolt-size-icon-sm;
  }
  @else if $size == md {
    height: vars.$bolt-size-icon-md;
    width: vars.$bolt-size-icon-md;
  }
}

@mixin apply {
  bolt-icon {
    @content;
  }
}
