$sizes: ("sm" : $bolt-spacing-sm,
         "md" : $bolt-spacing-md, 
         "lg" : $bolt-spacing-lg, 
         "xl" : $bolt-spacing-xl,
         "xxl": $bolt-spacing-xxl,
         "xxxl": $bolt-spacing-xxxl);
$sizesSmaller: ("sm" : 0,
              "md" : $bolt-spacing-sm,
              "lg" : $bolt-spacing-md, 
              "xl" : $bolt-spacing-lg, 
              "xxl" : $bolt-spacing-xl,
              "xxxl": $bolt-spacing-xxl);
$sides: "left", "right", "bottom";

@each $size, $spacing-token in $sizes {
  .bolt-space {
    &-inner, &-inner-wide {
      &-#{$size} {
        padding: #{$spacing-token} !important; 
      }
    }

    @each $side in $sides {
      &-#{$side}-#{$size} {
        margin-#{$side}: #{$spacing-token} !important;
      }
    }
  }
}

@each $size, $spacing-token in $sizesSmaller {
  .bolt {
    &-space {
      &-inner-wide {
        &-#{$size} {
          padding-top: #{$spacing-token} !important;
          padding-bottom: #{$spacing-token} !important; 
        }
      }
    }
  }
}
