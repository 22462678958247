@use 'theme' as Theme;
@import 'utilities/component-reset';

// Resets for use with or without bolt-typography class
.bolt-typography bolt-header, bolt-header {
  * {
    @extend %component-reset;
  }

  font-family: $bolt-font-family-sans-serif;

  ul, li, ul ul, ul ul li{
    margin: 0;
    padding: 0;
  }
  li{
    text-indent: 0;
    list-style: none;
  }
  a, li a {
    border: none;
    border-color: transparent;
  }
}
// End resets

.bolt-scroll-lock{
  overflow: hidden!important;
}

bolt-header {
  color: var(--bolt-foreground-color, Theme.$foreground-color);

  [slot="logo"]{
    display: flex;
    align-items: center;

    a{
      border-bottom: 0;
    }

    .divider{
      align-self: stretch;
      margin: 0 $bolt-spacing-md;
      border-right: 1px solid $bolt-color-rebrand-dark-gray;
    }

    > * + * {
      margin-left: $bolt-spacing-sm;
    }
  }
  [slot="nav-actions"], [slot="main-actions"]{
    display: flex;

    bolt-button {
      white-space: nowrap;
    }
  }
  .bolt-header-wc--nav-item{
    background: none;
    border: none;
    color: $bolt-text-link-default;
    cursor: pointer;
    padding: $bolt-spacing-lg;
    transition: background-color $bolt-transition-timing-med;

    &:hover{
      background: $bolt-color-rebrand-pale-gray-50;
    }
    &:focus{
      box-shadow: $bolt-interactive-focus;
      outline: none;
    }
    &:not(:focus-visible){
      box-shadow: none;
    }
  }
}

bolt-header[bolt-media="desktop"]{
  .bolt-header-mobile-only{
    display: none!important;
  }
  ul[slot="utility-links"], ul[slot="external-links"]{
    font-size: $bolt-font-size-xs;
    padding: $bolt-spacing-md 0;

    li{
      display: inline-block;
      line-height: 21px;

      &:not(:last-of-type)::after{
        content: "|";
        color: $bolt-color-blue;
        padding: 0 $bolt-spacing-md;
      }

      a{
        border-bottom: 1px solid currentColor;

        &:hover, &:focus{
          border-color: transparent;
        }
      }
    }
  }
  [slot="nav-actions"], [slot="main-actions"]{
    > * + * {
      margin-left: $bolt-spacing-lg;
    }
  }
  ul[slot="navigation-primary"]{
    display: flex;
  }
  .bolt-header-wc--nav-item{
    display: block;
    margin-top: $bolt-spacing-sm;
    padding-bottom: calc(#{$bolt-spacing-lg} + #{$bolt-spacing-sm});
  }
  a.bolt-header-wc--nav-item{
    text-decoration: underline;
    text-underline-offset: $bolt-spacing-xs;
    text-decoration-color: transparent;
    transition: all $bolt-transition-timing-fast;

    &:hover, &:focus {
      text-decoration-color: currentColor;
      background: none;
    }
  }
  button.bolt-header-wc--nav-item{
    border: 1px solid transparent;
    position: relative;

    bolt-icon{
      display: none;
    }

    &:hover{
      border-color: $bolt-color-neutral-59;
      border-bottom-color: transparent;
    }

    &[aria-expanded="true"]{
      color: $bolt-color-rebrand-charcoal;
      border-bottom: 1px solid transparent;
      border-color: $bolt-color-neutral-59;
      background: $bolt-background-white;
      z-index: $bolt-z-index-low; // Prevent obscuring of focus ring

      &::before{
        content: '';
        display: block;
        width: calc(100% + 2px);
        height: 9px;
        background: $bolt-color-rebrand-vibrant-blue;
        position: absolute;
        top: -1px;
        left: -1px;
      }

      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: white;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
}

bolt-header[bolt-media="mobile"]{
  .bolt-header-desktop-only{
    display: none!important;
  }
  li a{
    padding: $bolt-spacing-lg;
    transition: background-color $bolt-transition-timing-med;

    &:hover{
      background: $bolt-color-rebrand-pale-gray-50;
    }
    &:active{
      background: $bolt-color-rebrand-vibrant-blue;
      color: $bolt-background-white;
    }
  }
  .bolt-header-wc--nav-item{
    display: flex;
    justify-content: space-between;
    width: 100%;

    bolt-icon[name="chevron-right"]{
      display: block;
    }
    bolt-icon[name="chevron-left"]{
      display: none;
    }
  }
  [slot="search"]{
    border-bottom: 1px solid $bolt-color-neutral-59;
    padding: $bolt-spacing-lg;
  }
  [slot="nav-actions"] {
    flex-direction: column;

    bolt-button{
      &::part(root) {
        justify-content: left;
        border-radius: 0;
        padding: 20px;
        border: 0;
        border-bottom: 1px solid $bolt-color-white;
      }
    }
  }
  ul[slot="external-links"] li a, ul[slot="utility-links"] li a {
    display: block;
  }
  ul[slot="utility-links"]{
    background: $bolt-background-white;
  }

  &[bolt-menu-level="2"]{
    .bolt-header-wc--nav-item:not([aria-expanded="true"]){
      padding: 0;
    }
    .bolt-header-wc--nav-item[aria-expanded="true"]{
      border-bottom: 1px solid $bolt-color-neutral-59;
      justify-content: left;

      bolt-icon[name="chevron-right"]{
        display: none;
      }
      bolt-icon[name="chevron-left"]{
        display: block;
        margin-right: $bolt-spacing-md;
      }
    }
  }
}

/* Styles for markup patterns inside panels */
bolt-header{
  .bolt-header-panel{
    &-links-all{
      display: block;
      width: 100%;

      a{
        display: flex;
        align-items: center;
      }

      bolt-icon{
        margin-right: $bolt-spacing-sm;
      }
    }
    &-links-title{
      font-size: $bolt-font-size-xs;
      text-transform: uppercase;
      font-weight: $bolt-font-weight-bold;
    }
    &-footer{
      background: $bolt-color-rebrand-pale-gray-25;
      width: 100%;
    }
  }
  h2.bolt-header-panel-links-title{
    margin-bottom: 0;
  }
  .bolt-header-promo{
    &-tile{
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      padding: $bolt-spacing-md;
      transition: background-color $bolt-transition-timing-med;

      &:hover, &:focus{
        background: $bolt-color-rebrand-pale-gray-50;
      }
      &:active{
        background: $bolt-color-rebrand-pale-gray;
      }
    }
    &-image{
      margin-right: $bolt-spacing-md;
    }
    &-content{
      flex: 1;
      min-width: $bolt-spacing-4xl;
    }
    &-text{
      font-size: $bolt-font-size-xs;
      color: $bolt-color-rebrand-charcoal;
    }
  }

  &[bolt-media="desktop"]{
    .bolt-header-panel{
      left: 0;
      position: absolute;
      top: 100%;
      width: 100%;
      background: $bolt-background-white;
      display: flex;
      flex-wrap: wrap;

      &-main{
        padding: $bolt-spacing-xl 0;
        width: 100%;
      }
      &-links{
        display: flex;
        font-size: 16px; // TODO: tokenize this
        line-height: normal;

        &-all{
          font-size: 16px; // TODO: tokenize this
          line-height: normal;
          margin-bottom: $bolt-spacing-xl;
          align-items: center;
          display: inline-flex;

          a{
            text-decoration: underline;
            text-underline-offset: $bolt-spacing-xs;
            text-decoration-color: transparent;
            transition: all $bolt-transition-timing-fast;

            &:hover, &:focus{
              text-decoration-color: currentColor;
            }
          }
        }
        &-title{
          margin-bottom: $bolt-spacing-lg;
          margin-top: 0;
        }
        &-list{
          &:not(:last-of-type){
            margin-right: $bolt-spacing-xl;
          }
          li{
            margin-bottom: $bolt-spacing-lg;
            max-width: 240px;
          }
        }
        li a{
          border-bottom: 1px solid transparent;

          &:hover, &:focus{
            border-color: currentColor;
          }
        }
      }
      &-footer{
        padding: $bolt-spacing-md 0;
      }
      &-promos{
        display: flex;
        flex-basis: 100%;
      }
    }
    .bolt-header-container{
      margin: 0 auto;
      max-width: 1172px; // Todo: tokenize or modify make-container mixin
      padding: 0 $bolt-spacing-xl;
      width: 100%;
    }

    // Todo: Generalize helper class for use outside header
    .bolt-text-columns{
      &-2{
        column-count: 2;
      }
      &-3{
        column-count: 3;
      }
      &-4{
        column-count: 4;
      }
      &-5{
        column-count: 5;
      }
      &-6{
        column-count: 6;
      }
    }
  }

  &[bolt-media="mobile"]{
    .bolt-header-panel-links{
      &-list{
        margin-bottom: $bolt-spacing-md;

        &:not(:first-child){
          border-top: 1px solid $bolt-color-neutral-59;
        }
      }
      &-title{
        padding: $bolt-spacing-lg;
        padding-bottom: $bolt-spacing-sm;
      }
      &-all{
        margin-top: -$bolt-spacing-md; // Offset margin from list that precedes it
        margin-bottom: $bolt-spacing-md;
      }
      & li a{
        display: block;
      }
      & li a, &-all a {
        padding-left: $bolt-spacing-2xl;

        bolt-icon {
          margin-left: -#{$bolt-spacing-xl}; // Align text, not icon, with other items in list
          margin-right: $bolt-spacing-sm;
          vertical-align: middle;
        }
      }
    }
  }
}


/* Transitions */
bolt-header[bolt-media="desktop"]{

  // Prevent transitions on page load
  &[bolt-loading] .bolt-header-panel{
    transition: none;
  }

  // Panel switching
  .bolt-header-panel {
    opacity: 0;
    visibility: hidden;
    transition: all $bolt-transition-timing-fast $bolt-transition-timing-fast;
  }
  [aria-expanded="true"] ~ .bolt-header-panel {
    transition: all $bolt-transition-timing-fast;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  .bolt-header-wc--nav-item{
    transition: color $bolt-transition-timing-fast, background-color $bolt-transition-timing-fast;
    &[aria-expanded="true"]{
      transition: color $bolt-transition-timing-fast, background-color $bolt-transition-timing-fast;
    }
  }

  // Panel open/close
  &[menu-toggle]{
    [aria-expanded="true"] ~ .bolt-header-panel{
      transition: all $bolt-transition-timing-fast;
    }
    .bolt-header-panel {
      transition: all $bolt-transition-timing-fast;
    }
  }
}

// Menu depth switching
bolt-header[bolt-media="mobile"]{

  // Level 1 items
  [slot="nav-actions"], [slot="external-links"], [slot="utility-links"], .bolt-header-wc--nav-item{
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: visibility $bolt-transition-timing-med, opacity $bolt-transition-timing-med, background-color $bolt-transition-timing-med, height 0s;
  }
  &[bolt-menu-level="2"]{
    [slot="nav-actions"], [slot="external-links"], [slot="utility-links"], .bolt-header-wc--nav-item:not([aria-expanded="true"]){
      visibility: hidden;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: visibility $bolt-transition-timing-med, opacity $bolt-transition-timing-med, background-color $bolt-transition-timing-med, height 0s $bolt-transition-timing-med;
    }
  }

  // Level 2 items
  .bolt-header-panel{
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all $bolt-transition-timing-med, height 0s $bolt-transition-timing-med;
  }
  &[bolt-menu-level="2"]{
    .bolt-header-wc--nav-item[aria-expanded="true"] ~ .bolt-header-panel{
      visibility: visible;
      opacity: 1;
      height: auto;
      transition: all $bolt-transition-timing-med, height 0s;
    }
  }
}
